import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import stripe from "./stripe";
import auth from "./auth";

export default new Vuex.Store({
  modules: {
    stripe,
    auth
  }
});
