import fb from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";

// Get a Firestore instance
export const firebase = fb.initializeApp({
  apiKey: "AIzaSyAfR9myYM_JgdzsE5-SO0230wQ0YIwn7iM",
  authDomain: "pcc-events.firebaseapp.com",
  projectId: "pcc-events",
  storageBucket: "pcc-events.appspot.com",
  messagingSenderId: "1042489118610",
  appId: "1:1042489118610:web:3eeeb0b230ca9fa7fdbb4b",
});
export const db = firebase.firestore();

export const auth = firebase.auth();

export const functions = firebase.functions();

import store from "@/store";

// Use local emulators
if (location.hostname === "localhost") {
  db.settings({
    host: "localhost:8000",
    ssl: false,
  });
  auth.useEmulator("http://localhost:9099/");
  functions.useFunctionsEmulator("http://localhost:5001");
}

auth.onAuthStateChanged(function (user) {
  if (user) {
    // User is signed in.
    store.dispatch("USER_LOGGED_IN", user);
  } else {
    // User is signed out.
    store.dispatch("USER_LOGGED_OUT");
  }
});
