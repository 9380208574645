import Vue from "vue";
import VueRouter from "vue-router";
import Bingo from "../views/Bingo.vue";
// import BingoAdministration from "../views/BingoAdministration.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/bingo",
    alias: "/",
    name: "Bingo",
    component: Bingo
  },
  {
    path: "/bingo/administration",
    name: "BingoAdministration",
    component: () => import("../views/BingoAdministration.vue")
  },
  {
    path: "/success",
    name: "Success",
    component: () => import("../views/Success.vue")
  },
  {
    path: "/cancel",
    name: "Cancel",
    component: () => import("../views/Cancel.vue")
  },
  {
    path: "/bonus-raffle",
    name: "BonusRaffle",
    component: () => import("../views/BonusRaffle.vue")
  },
  // {
  //   path: "/golf",
  //   name: "Golf",
  //   component: () => import("../views/Golf.vue")
  // },
  // {
  //   path: "/golf/success",
  //   name: "GolfSuccess",
  //   component: () => import("../views/GolfSuccess.vue")
  // },
  // {
  //   path: "/golf/cancel",
  //   name: "GolfCancel",
  //   component: () => import("../views/GolfCancel.vue")
  // },
  {
    path: "/chinese-auction",
    name: "ChineseAuction",
    component: () => import("../views/ChineseAuction.vue")
  },
  {
    path: "/chinese-auction/cancel",
    name: "ChineseAuctionCancel",
    component: () => import("../views/ChineseAuctionCancel.vue")
  },
  {
    path: "/chinese-auction/success",
    name: "ChineseAuctionSuccess",
    component: () => import("../views/ChineseAuctionSuccess.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
