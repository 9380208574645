import { loadStripe } from "@stripe/stripe-js";
const stripePublicKey =
  location.hostname === "localhost"
    ? "pk_test_51HfZpmDyj1l4eCgiyDIpeoTOhsGyjpS0Wk8r1F0hNwSI6DWI8158NzIHZ6S6jzpdmF4XdKCAxoHnqL9BSXaZ1bn500cD9ONAlB"
    : "pk_live_51HfZpmDyj1l4eCgiVDZzb8N1Su3llIqyfaLLFzMAQtKN580TtkIYA3rWX3tNToT82WzoPY4iLIm3fgbDeTWmnaZu00wLge4JlI";
import { functions } from "@/firebase";

export default {
  state: {
    stripe: null,
    stripeCustomer: null
  },
  getters: {},
  mutations: {
    SET_STRIPE(state, stripe) {
      state.stripe = stripe;
    },
    SET_STRIPE_CUSTOMER(state, customer) {
      state.stripeCustomer = customer;
    }
  },
  actions: {
    async LOAD_STRIPE({ commit }) {
      commit("SET_STRIPE", await loadStripe(stripePublicKey));
    },
    async FETCH_STRIPE_CUSTOMER({ commit }) {
      const { data } = await functions.httpsCallable("getUserStripeCustomer")();
      commit("SET_STRIPE_CUSTOMER", data);
    }
  }
};
