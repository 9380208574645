<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box">
        <h2 class="title">Confirm your contact details and click checkout!</h2>

        <form @submit.prevent="submit">
          <div class="field">
            <div class="control">
              <input type="text" class="input" v-model.trim="name" placeholder="Your full name" required />
            </div>
          </div>

          <div class="field">
            <div class="control">
              <input type="tel" class="input" v-model.trim="phoneNumber" placeholder="Your cell phone number (optional)" />
            </div>
          </div>

          <slot></slot>
          <button class="button is-success is-fullwidth" :class="{ 'is-loading': isLoading }">
            Checkout
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { auth, functions } from "@/firebase";

export default {
  name: "PayInfoModal",
  data() {
    return {
      name: `${localStorage.getItem("firstName") ?? ""} ${localStorage.getItem(
        "lastName"
      ) ?? ""}`.trim(),
      phoneNumber: "",
      isLoading: false
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    stripeCustomer() {
      return this.$store.state.stripe.stripeCustomer;
    }
  },
  watch: {
    "user.profile.displayName": {
      immediate: true,
      handler(newDisplayName) {
        if (newDisplayName) {
          this.name = newDisplayName;
        }
      }
    },
    "stripeCustomer.phone": {
      immediate: true,
      handler(newPhoneNumber) {
        this.phoneNumber = newPhoneNumber;
      }
    }
  },
  methods: {
    submit() {
      if (this.isLoading) return;

      this.isLoading = true;

      Promise.all([
        this.$store
          .dispatch("UPDATE_USER_PROFILE", {
            displayName: this.name
          }),
        functions.httpsCallable("updateUserStripeCustomer")({
          updates: {
            name: this.name,
            phone: this.phoneNumber
          }
        })
      ])
        .then(([_, { data }]) => {
          console.log(_);
          this.$store.commit("SET_USER_PROFILE", auth.currentUser);

          this.$store.commit("SET_STRIPE_CUSTOMER", data);
          this.isLoading = false;

          this.$emit("checkout");
        })
        .catch(console.error);
    }
  }
};
</script>

<style scoped></style>
