<template>
  <div
    class="bingo-ball is-unselectable"
    :class="[trayId + '-tray', disabled ? 'disabled' : '']"
    :data-letter="ballLetter"
    @click="handleClick"
  >
    <span class="ball-letter">{{ ballLetter }}</span>
    <span class="ball-number">{{ number }}</span>
  </div>
</template>

<script>
import { ballLetter } from "@/utils";

export default {
  name: "BingoBall",
  props: {
    trayId: { type: String, required: true },
    number: { type: Number, required: true },
    disabled: { type: Boolean, default: false }
  },
  computed: {
    ballLetter() {
      return ballLetter(this.number);
    }
  },
  methods: {
    handleClick() {
      if (this.disabled) return;

      this.$emit("select", { trayId: this.trayId, number: this.number });
    }
  }
};
</script>

<style lang="scss" scoped>
.bingo-ball {
  margin: 10px;

  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: 75px;
  height: 75px;
  border-radius: 100%;
  border: 1px solid #222;
  background-color: var(--color);
  color: #222;

  /* Center in ball */
  align-items: center;
  justify-content: center;

  // background: radial-gradient(
  //   circle at 50% 120%,
  //   hsl(var(--color), 100%),
  //   hsl(var(--color), 45%) 10%,
  //   hsl(var(--color), 25%) 80%,
  //   hsl(var(--color), 20%) 100%
  // );

  box-shadow: 0 0 3px black;
  transition: 0.2s box-shadow;

  cursor: pointer;

  .ball-letter {
    font-size: 1.2rem;
    z-index: 3;
  }

  .ball-number {
    margin-top: -10px;
    font-size: 1.5rem;
    font-weight: bold;
    z-index: 3;
  }

  &:before {
    content: "";
    position: absolute;
    top: -5%;
    left: 20%;
    height: 90%;
    width: 90%;
    border-radius: 100%;
    background: radial-gradient(
      circle at 50% -5px,
      #ffffff,
      rgba(255, 255, 255, 0) 58%
    );
    filter: blur(5px);
    z-index: 2;
  }

  &:not(.disabled):hover {
    box-shadow: 0px 0px 3px 2px black;
  }
}

.bingo-ball.white-tray {
  background-color: #eee;
}

.bingo-ball.rainbow-tray[data-letter="B"] {
  background-color: rgb(69, 69, 243);
}

.bingo-ball.rainbow-tray[data-letter="I"] {
  background-color: rgb(240, 116, 116);
}

.bingo-ball.rainbow-tray[data-letter="N"] {
  background-color: orange;
}

.bingo-ball.rainbow-tray[data-letter="G"] {
  background-color: rgb(72, 228, 72);
}

.bingo-ball.rainbow-tray[data-letter="O"] {
  background-color: rgb(251, 251, 93);
}

.bingo-ball.dalmatian-tray {
  // background-image: radial-gradient(#212121 20%, #eee 20%);
  background-position: 0 0;
  background-size: 100% 100%;
  background-image: url(../assets/dalmatian.jpg);
}

.bingo-ball.dalmatian-tray:nth-child(even) {
  background-position: 50% 50px;
  background-size: 90% 90%;
}

.bingo-ball.dalmatian-tray:nth-child(3n) {
  background-position: -50% 20px;
  background-size: 90% 90%;
}

.bingo-ball.gold-tray {
  background-color: gold;
}

.bingo-ball.peppermint-tray {
  background: repeating-linear-gradient(
    45deg,
    white,
    white 10px,
    rgb(245, 89, 89) 10px,
    rgb(245, 89, 89) 20px
  );
}

.bingo-ball.snowflake-tray {
  background-image: url(../assets/snowflake.jpg);
  background-position: center;
  background-size: 200%;
}

.bingo-ball.heart-tray {
  background-image: url(https://www.onlygfx.com/wp-content/uploads/2019/06/6-pink-heart-pattern-2.png);
  background-position: center;
  // background-repeat: ;
  background-size: 75%;
}

.bingo-ball.shamrock-tray {
  background-image: url(../assets/shamrock.png);
  background-position: center;
  // background-repeat: ;
  background-size: 130%;
}

.bingo-ball.baseball-tray {
  background-image: url(https://toppng.com/uploads/preview/baseball-115309799481pxkrvfux4.png);
  background-position: center;
  // background-repeat: ;
  background-size: 105%;
}

.bingo-ball.star-tray {
  background-image: url(https://previews.123rf.com/images/alexoakenman/alexoakenman1806/alexoakenman180600123/103432162-vector-yellow-stars-background-element-in-flat-style.jpg);
  background-position: center;
  // background-repeat: ;
  background-size: 250%;
}

.bingo-ball.flower-tray {
  background-image: url(../assets/flower.png);
  background-position: center;
  // background-repeat: ;
  background-size: 100%;
}

.bingo-ball.infinity-tray {
  background-image: url(../assets/infinity.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.bingo-ball.pumpkin-tray {
  background-color: orange;
}

.bingo-ball.disabled {
  cursor: not-allowed;
  opacity: 0.2;
}
</style>
