<template>
  <nav class="navbar is-info" role="navigation">
    <div class="container">
      <div class="navbar-brand">
        <a href="#" class="navbar-item">PCC Fundraising</a>

        <a
          role="button"
          class="navbar-burger burger"
          :class="{ 'is-active': isExpanded }"
          aria-label="menu"
          aria-expanded="false"
          data-target="top-navbar"
          @click="isExpanded = !isExpanded"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        id="top-navbar"
        class="navbar-menu"
        :class="{ 'is-active': isExpanded }"
      >
        <div class="navbar-start">
          <!-- <router-link
            class="navbar-item"
            :to="{ name: 'Bingo' }"
            active-class="is-active"
            >Bingo Ball 50/50 Raffle</router-link
          > -->
        </div>
        <div v-if="user.profile" class="navbar-end">
          <a href="#" class="navbar-item"
            >Logged in as
            <strong class="ml-1">{{ user.profile.email }}</strong></a
          >
          <a href="#" class="navbar-item" @click="logout">Logout</a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { auth } from "../firebase";

export default {
  name: "TopNavbar",
  data() {
    return {
      isExpanded: false
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    user() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    logout() {
      auth.signOut();
    }
  }
};
</script>

<style></style>
