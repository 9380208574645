<template>
  <div>
    <TopNavbar />
    <main>
      <LoginModal v-if="!isLoggedIn" />
      <router-view v-else />
    </main>
    <footer class="footer">
      <p class="has-text-centered">
        Having issues? Contact
        <a href="mailto:technology@prestoncenterofcompassion.org"
          >technology@prestoncenterofcompassion.org</a
        >
        for support.
      </p>
    </footer>
  </div>
</template>

<script>
import { auth } from "./firebase";

import TopNavbar from "./components/TopNavbar";
import LoginModal from "./components/LoginModal";

export default {
  name: "PCCFundraising",
  components: {
    TopNavbar,
    LoginModal
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    stripeCustomer() {
      return this.$store.state.stripe.stripeCustomer;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    }
  },
  async created() {
    if (
      auth.isSignInWithEmailLink(window.location.href) &&
      !this.$store.getters.isLoggedIn
    ) {
      const email =
        window.localStorage.getItem("emailForSignIn") ||
        this.$route.query.email ||
        window.prompt("Please provide your email for confirmation");
      window.localStorage.removeItem("emailForSignIn");
      try {
        await auth.signInWithEmailLink(email, window.location.href);
        window.localStorage.setItem("firstName", this.$route.query.firstName ?? "");
        window.localStorage.setItem("lastName", this.$route.query.lastName ?? "");
        return this.$router.push(window.location.pathname);
      } catch (err) {
        alert(err);
        console.error(err);
      }
    }
  }
};
</script>
<style lang="scss"></style>
