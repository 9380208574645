import { firebase, auth } from "@/firebase";

export default {
  state: {
    user: {
      profile: null,
      data: null
    },
    userDataUnsubscribe: null,
    now: new Date()
  },
  getters: {
    isLoggedIn: state => state.user.profile !== null,
    userName: state => state.user.profile.displayName,
    userEmail: state => state.user.profile.email
  },
  mutations: {
    SET_USER_PROFILE(state, profile) {
      state.user = { ...state.user, profile };
    },
    SET_USER_DATA(state, data) {
      state.user = { ...state.user, data };
    },
    SET_USER_DATA_UNSUNSCRIBE(state, unsubscribe) {
      state.userDataUnsubscribe = unsubscribe;
    }
  },
  actions: {
    async UPDATE_USER({ state }, updates) {
      return firebase
        .firestore()
        .collection("users")
        .doc(state.user.profile.email)
        .update(updates);
    },
    UPDATE_USER_PROFILE(ctx, updates) {
      console.log(updates);
      return auth.currentUser.updateProfile(updates);
    },
    USER_LOGGED_IN({ dispatch, commit }, user) {
      commit("SET_USER_PROFILE", user);
      commit(
        "SET_USER_DATA_UNSUNSCRIBE",
        firebase
          .firestore()
          .collection("users")
          .doc(user.email)
          .onSnapshot(function(doc) {
            commit("SET_USER_DATA", doc.data());
            setTimeout(() => {
              dispatch("FETCH_STRIPE_CUSTOMER");
            }, 60 * 1000)
          })
      );
    },
    USER_LOGGED_OUT({ state, commit }) {
      commit("SET_USER_PROFILE", null);

      if (state.userDataUnsubscribe) state.userDataUnsubscribe();
      commit("SET_USER_DATA_UNSUNSCRIBE", null);
      commit("SET_USER_DATA", null);
      commit("SET_STRIPE_CUSTOMER", null);
      // commit("SET_LOADED", true);

      // if (
      //   router.currentRoute.matched.some(record => record.meta.requiresAuth) &&
      //   !getters.loggedIn
      // ) {
      //   router.push({ name: "Home" });
      // }
    },
    LOG_OUT() {
      return auth.signOut();
    }
  }
};
